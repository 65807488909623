// TermsOfService.js
import React from 'react';
import { Link } from 'react-router-dom';

function TermsOfService() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white flex flex-col items-center justify-start md:p-8">
      <div className="max-w-6xl w-full bg-gray-900 lg:rounded-3xl shadow-2xl p-12 lg:p-24">
        <h1 className="text-5xl lg:text-7xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Terms of Service</h1>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          Please read these Terms of Service ("Terms", "Terms of Service", "TOS") carefully before using the AI Joingate Bot and this website, https://ai-joingate.xyz/*, both described as Service, operated by <a href="https://icelavaman.com" className="text-blue-400 hover:text-blue-300 transition duration-300">IceLavaMan</a> ("us", "we", or "our").
        </p>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service.
        </p>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          By accessing or using the Service you agree to be bound by these Terms. We reserve the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">The Service</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          AI Joingate provides a Discord application. AI Joingate is available via the invitation link located at <a href="https://ai-joingate.xyz" className="text-blue-400 hover:text-blue-300 transition duration-300">https://ai-joingate.xyz</a>.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Automated Verification</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          AI Joingate is a Discord bot that automates the verification process for new members on Discord servers. It engages in an interactive conversation with new members to ensure they have read and understood the server rules and are ready to become active members of the community.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Prohibited Activities</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          It is strictly prohibited to create automated programs or use any other means to circumvent or automate the verification process provided by AI Joingate. Any attempt to bypass the verification process is a violation of these Terms of Service.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Modifications to the Terms</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        </p>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
        </p>
        <p className="text-lg lg:text-xl text-gray-300">
          Last updated: May 10, 2024
        </p>
      </div>
      <footer className="w-full max-w-6xl text-center py-6 mt-12 lg:mt-16">
        <Link to="/" className="text-blue-400 hover:text-blue-300 transition duration-300">
          Back to Home
        </Link>
      </footer>
    </div>
  );
}

export default TermsOfService;
