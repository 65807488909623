// PrivacyPolicy.js
import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white flex flex-col items-center justify-start md:p-8">
      <div className="max-w-6xl w-full bg-gray-900 lg:rounded-3xl shadow-2xl p-12 lg:p-24">
        <h1 className="text-5xl lg:text-7xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-500">Privacy Policy</h1>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          At AI Joingate, accessible via Discord, one of our main priorities is the privacy of our users. This Privacy Policy document contains types of information that is collected and recorded by AI Joingate and how we use it.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Data Collected from Server Owners</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          AI Joingate collects the following data from server owners who use our Discord bot:
        </p>
        <ul className="list-disc ml-6 mb-8 text-lg lg:text-xl text-gray-300">
          <li>Server ID</li>
          <li>User ID</li>
          <li>Role IDs</li>
          <li>Channel IDs</li>
        </ul>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Data Collected from Server Members</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          AI Joingate collects the following data from server members during the verification process:
        </p>
        <ul className="list-disc ml-6 mb-8 text-lg lg:text-xl text-gray-300">
          <li>User ID</li>
          <li>Message IDs (until the user is verified)</li>
        </ul>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          Once a user is verified, the message IDs are deleted and no longer stored by AI Joingate.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Deleting Your Data</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          If you wish to delete your data, you can do so by joining our Support Server <a href="https://discord.gg/qQZmcDjnf4" className="text-blue-400 hover:text-blue-300 transition duration-300">via this link</a> and contacting our support team.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Scope of this Privacy Policy</h2>
        <p className="text-lg lg:text-xl mb-8 text-gray-300">
          This Privacy Policy applies only to the AI Joingate service provided through Discord. On our website, we only use technically necessary cookies.
        </p>
        <h2 className="text-4xl lg:text-5xl font-semibold mb-6 text-gray-100">Consent</h2>
        <p className="text-lg lg:text-xl text-gray-300">
          By using our service, you hereby consent to our Privacy Policy and agree to its terms.
        </p>
      </div>
      <footer className="w-full max-w-6xl text-center py-6 mt-12 lg:mt-16">
        <Link to="/" className="text-blue-400 hover:text-blue-300 transition duration-300">
          Back to Home
        </Link>
      </footer>
    </div>
  );
}

export default PrivacyPolicy;
