// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import logo from "./ai-joingate-bot-logo.webp";
import TermsOfService from "./tos";
import PrivacyPolicy from "./pp";

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white flex flex-col items-center justify-between md:p-8">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div className="max-w-6xl w-full bg-gray-900 lg:rounded-3xl shadow-2xl p-12 lg:p-24">
                <div className="flex flex-col lg:flex-row items-center justify-center">
                  <img
                    src={logo}
                    alt="AI Joingate Bot Logo"
                    className="w-40 h-40 lg:w-60 lg:h-60 rounded-full transition duration-500 transform"
                  />
                  <div className="text-center lg:text-left mt-6 lg:mt-0 lg:ml-12">
                    <h1 className="text-5xl lg:text-7xl font-bold text-transparent bg-clip-text bg-gradient-to-r py-6 from-blue-400 to-purple-500">
                      AI Joingate
                    </h1>
                    <p className="mt-4 text-gray-300 text-lg lg:text-2xl transition duration-300 hover:text-gray-100">
                      Automate your server's verification process with
                      AI-powered conversations.
                    </p>
                  </div>
                </div>
                <div className="mt-12 lg:mt-16 flex flex-col sm:flex-row justify-center gap-6 lg:gap-10">
                  <a
                    href="https://discord.gg/qQZmcDjnf4"
                    className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-lg text-white font-bold py-4 lg:py-5 px-8 lg:px-10 rounded-full shadow-lg text-center transition duration-300 transform lg:hover:scale-105"
                  >
                    Join Support Server
                  </a>
                  <a
                    href="https://discord.com/oauth2/authorize?client_id=1233013559365992458&permissions=8&scope=bot"
                    className="bg-gradient-to-r from-purple-600 to-purple-700 hover:from-purple-700 hover:to-purple-800 text-lg text-white font-bold py-4 lg:py-5 px-8 lg:px-10 rounded-full shadow-lg text-center transition duration-300 transform hover:scale-105"
                  >
                    Invite Bot
                  </a>
                </div>

                <div className="mt-16 lg:mt-24">
                  <h2 className="text-4xl lg:text-5xl font-semibold text-center text-gray-100">
                    Key Features
                  </h2>
                  <ul className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-8 lg:mt-12 lg:text-lg text-gray-300">
                    <li className="flex items-center transition duration-300 hover:text-gray-100">
                      <span className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 mr-3 lg:mr-4 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full transition duration-300"></span>
                      Automated verification process for new members
                    </li>
                    <li className="flex items-center transition duration-300 hover:text-gray-100">
                      <span className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 mr-3 lg:mr-4 bg-gradient-to-r from-green-500 to-green-600 rounded-full transition duration-300"></span>
                      AI-powered interactive conversations
                    </li>
                    <li className="flex items-center transition duration-300 hover:text-gray-100">
                      <span className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 mr-3 lg:mr-4 bg-gradient-to-r from-yellow-500 to-yellow-600 rounded-full transition duration-300"></span>
                      Customizable settings for server-specific requirements
                    </li>
                    <li className="flex items-center transition duration-300 hover:text-gray-100">
                      <span className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8 mr-3 lg:mr-4 bg-gradient-to-r from-red-500 to-red-600 rounded-full transition duration-300"></span>
                      Automatic role assignment upon successful verification
                    </li>
                  </ul>
                </div>
              </div>
            }
          />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/pp" element={<PrivacyPolicy />} />
        </Routes>
        <footer className="w-full max-w-6xl text-center py-6 mt-12 lg:mt-16">
          <p className="text-sm lg:text-base text-gray-400">
            &copy; {new Date().getFullYear()}{" "}
            <a
              href="https://icelavaman.com"
              className="text-blue-400 hover:text-blue-300 transition duration-300"
            >
              IceLavaMan
            </a>
          </p>
          <div className="mt-4 flex justify-center space-x-6 lg:space-x-8">
            <Link
              to="/tos"
              className="text-sm lg:text-base text-gray-400 hover:text-gray-300 transition duration-300"
            >
              Terms of Service
            </Link>
            <Link
              to="/pp"
              className="text-sm lg:text-base text-gray-400 hover:text-gray-300 transition duration-300"
            >
              Privacy Policy
            </Link>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
